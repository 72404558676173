import simleFace from "../../assets/img/simleFace.png";
import sadFace from "../../assets/img/sadFace.png";
import "./factor.style.scss";
import { useEffect, useState } from "react";
import FactorModel from "../models/factor-model/FactorModel";
interface IProps {
  positive: any;
  negative: any;
  userId: any;
  showValue?: any;
  setShowValue?: any;
  showFactorEdit?: any;
  setShowFactorEdit?: any;
  scoreCardId?: any;
  flagUpdated: any;
  setFlagUpdated: any;
  redirectionFactor:any;
  setRedirectionFactor:any;
  data:any;
  setData:any;
}
function Factor({
  flagUpdated,
  setFlagUpdated,
  positive,
  negative,
  userId,
  setShowValue,
  showFactorEdit,
  setShowFactorEdit,
  showValue,
  scoreCardId,
  redirectionFactor,
  setRedirectionFactor, 
  data,
  setData
}: IProps) {
  const factorEdit = (iconType: string, show: boolean) => {
    setRedirectionFactor(iconType)
      if (iconType == "Positive") {
        setData([positive]);
        setShowFactorEdit(show);
        setShowValue(false);
      } else if(iconType == "Negative") {
        setData([negative]);
        setShowFactorEdit(show);
        setShowFactorEdit(true);
        setShowValue(false);
      }
  };
  return (
    <>
      <div className="col-lg-12 mt-sx-5">
        <div className="postivefactor">
          <div className="pd1">
            <img src={simleFace} />
          </div>
          <div className="pd2">
            <i
              className="fa-solid fa-pen factor-edit-btn"
              onClick={() => factorEdit("Positive", true)}
            ></i>
            <h6 className="clr-green factorclr factorHeading">
              Positive factors
            </h6>
            <ul>
              <li>
                <span>
                  {positive?.pFactor1 == null ? "factor 1" : positive?.pFactor1}
                </span>
              </li>
              <li>
                <span>
                  {positive?.pFactor2 == null ? "factor 2" : positive?.pFactor2}
                </span>
              </li>
              <li>
                <span>
                  {positive?.pFactor3 == null ? "factor 3" : positive?.pFactor3}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="postivefactor postivefactor2">
          <div className="pd1">
            <i
              className="fa-solid fa-pen factor-edit-btn"
              onClick={() => factorEdit("Negative", true)}
            ></i>
            <img alt=" " src={sadFace} />
          </div>
          <div className="pd2">
            <h6 className="clr-red factorclr factorHeading">
              Negative factors
            </h6>
            <ul>
              <li>
                <span>
                  {negative?.nFactor1 == null ? "factor 1" : negative?.nFactor1}
                </span>
              </li>
              <li>
                <span>
                  {negative?.nFactor2 == null ? "factor 2" : negative?.nFactor2}
                </span>
              </li>
              <li>
                <span>
                  {negative?.nFactor3 == null ? "factor 3" : negative?.nFactor3}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {showFactorEdit || showValue  && data.length >0? (
        <FactorModel
          data={data}
          flagUpdated={flagUpdated}
          setFlagUpdated={setFlagUpdated}
          scoreCardId={scoreCardId}
          showFactorEdit={showFactorEdit}
          setShowFactorEdit={setShowFactorEdit}
          redirectionFactor={redirectionFactor}
          userId={userId}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default Factor;
