import "./header.style.scss";
import { Link, NavLink, useLocation} from "react-router-dom";
import LogoIcon from "../../assets/img/Logo.png";
import LogoIcon1 from "../../assets/img/Logo1.png";
import Notification from "../../assets/img/Notification.png";
import Avatar from "../../assets/img/hupe-life-favicon.png";
import SearchClose from "../../assets/img/close-icon.png";
import ArrowDown from "../../assets/img/DownArrowMobile.png";
import { useEffect, useState } from "react";
import { ToastOnLogout } from "../../shared/Toast/ToastMsg";
import { capitalizeFirstLetter } from "../../util/util";

interface IProps {
  checkIndex: any;
  selectedUser: any;
  selectedUserName: string;
  setSigninStatus: any;
  userData:any;
  userId:any;
}

const Header = ({ checkIndex, selectedUser, selectedUserName, setSigninStatus,userData,userId }: IProps) => {
  const location = useLocation();
  const [searchUsers, setSearchUsers] = useState(userData);
  const [searchText, setSearchText] = useState("");

  const searchingUser = (words?: any) => {
    setSearchText(words);
    if (words.length > 0) {
      const users = userData.filter((item: any) => {
        const fullName = item.userDetail.firstName + item.userDetail.lastName;
        return fullName.toLowerCase().includes(words.toLowerCase());
      });
      setSearchUsers(users);
    } else {
      setSearchUsers(userData);
    }
  };

  const logoutUser = () => {
    ToastOnLogout("Logout Successfully")
    sessionStorage.removeItem('AUTH_TOKEN');
    setSigninStatus(false);
  }
  
  useEffect(()=>{
    setSearchUsers(userData)
  },[userId])

  return (
    <>
      <header className="mainheader">
        <div className="topnav adminDestop">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/dashboard">
                <img src={LogoIcon} alt="" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item notification dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={Notification} alt="" />
                      <span>6</span>
                    </a>
                    <ul
                      className="dropdown-menu notificationDropdown"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          Alexander Cord added blood results
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Alexander Cord added liver analysis
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Alexander Cord added liver analysis
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item greenclr" href="#">
                          See all notifications
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item userprofile dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={Avatar} alt="" />
                    </a>
                    <ul
                      className="dropdown-menu samllDropdown"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          Hi, Sami
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Profile
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Settings
                        </a>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/ImportUser">
                          Import User
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/" onClick={() => logoutUser()}>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div className="topnav adminMobile">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <div className="mobileview-sec">
                <Link className="navbar-brand" to="/dashboard">
                  <img src={LogoIcon1} alt="" />
                </Link>
                <div className="clients-list">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown1"
                    data-bs-toggle="dropdown"
                  >
                    Clients
                  </a>
                  <div
                    className="dropdown-menu Mview"
                    aria-labelledby="navbarDropdown1"
                  >
                    <div className="search-bar1 ClientSearchPhone">
                      <input
                        type="text"
                        placeholder="Search"
                        value={searchText}
                        onChange={(e: any) => searchingUser(e.target.value)}
                      />
                      <img
                        className="searchCloseIcon"
                        src={SearchClose}
                        alt=""
                        onClick={() => {
                          searchingUser("");
                        }}
                      />
                    </div>
                    <div className="MobileSearch-User">
                    { searchUsers ? searchUsers.map((e: any, index: any) => {
                      return (
                        <a
                          className={
                            selectedUser.id === e.id
                              ? "nav-link active client-child"
                              : "nav-link client-child"
                          }
                          key={index}
                          onClick={() => checkIndex(e.id)}
                        >
                          {`${capitalizeFirstLetter(e.userDetail.firstName)}  ${capitalizeFirstLetter(e.userDetail.lastName)} `}
                        </a>
                      );
                    }) : null
                  }</div>
                  </div>
                </div>
                <div className="collapse navbar-collapse adminmo-list">
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0 flex-row justify-content-end">
                    <li className="nav-item notification dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={Notification} alt="" />
                        <span>6</span>
                      </a>
                      <ul
                        className="dropdown-menu notificationDropdown"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            Alexander Cord added blood results
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Alexander Cord added liver analysis
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Alexander Cord added liver analysis
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item greenclr" href="#">
                            See all notifications
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item userprofile dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={Avatar} alt=" " />
                      </a>
                      <ul
                        className="dropdown-menu samllDropdown"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            Hi, Sami
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Profile
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Settings
                          </a>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/ImportUser">
                            Import User
                          </Link>
                        </li>
                        <li>
                        <Link className="dropdown-item" to="/" onClick={() => logoutUser()}>
                          Logout
                        </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          <div className="bottomheaderSec ipadview">
            <ul className="menu-side">
              <li className="item">
                <p className="phoneHeading">{selectedUserName}</p>
                <div className="search-bar1 HiddenSearch">
                  <input type="text" placeholder="Alexander Cord" />
                </div>
              </li>
              <li className="item">
                <ul className="sub-list">
                  <li>
                    <NavLink
                      to="/dashboard"
                      className={({ isActive }) =>
                        isActive ? "active" : "inactive"
                      }
                    >
                      Dashboard
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/kpi"
                      className={({ isActive }) =>
                        isActive ? "active" : "inactive"
                      }
                    >
                      KPI
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/checkup-result"
                      className={({ isActive }) =>
                        isActive ? "active" : "inactive"
                      }
                    >
                      Blood Results
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="bottomheaderSec iphoneview">
            <ul className="menu-side">
              <li className="item ">
                <p className="phoneHeading">{selectedUserName}</p>
                <div className="search-bar1 HiddenSearch">
                  <input type="text" placeholder=" Alexander Cord" />
                </div>
              </li>
              <li className="item">
                <div className="clients-list">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown2"
                    data-bs-toggle="dropdown"
                  >
                    {location.pathname.includes("dashboard")
                      ? "Dashboard"
                      : location.pathname.includes("kpi")
                        ? "KPI"
                        : "Blood Results"}

                    <img className="mobileArrowDown" src={ArrowDown} alt="" />
                  </a>

                  <div
                    className="dropdown-menu Mview"
                    aria-labelledby="navbarDropdown2"
                  >
                    <Link className="nav-link dashboardChlid" to="/dashboard">
                      Dashboard
                    </Link>
                    <Link className="nav-link dashboardChlid" to="/kpi">
                      KPI
                    </Link>
                    <Link
                      className="nav-link dashboardChlid"
                      to="/checkup-result"
                    >
                      Blood Results
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
