import "./spinner.style.scss"

const Spinner = () =>{
    return(
        <div className="spinner-loading">
            <div className="loader"></div>
        </div>
    )
}

export default Spinner