import React from "react";
import { Line } from "react-chartjs-2";
import { prctGenerate } from "../../util/util";

interface IProps {
  labels?: any;
  datasets?: any;
  data?: any;
}

const LineChart: React.FC<IProps> = ({ data, labels}) => {
  const options = {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "",
    },
    scales: {
      yAxes: [{
        min:5,
        max: 10,
        offset: true,
          ticks: {
              // beginAtZero: true,
              min:data[0] -prctGenerate(data[0]),
              stepSize: 10,
              precision: 0
          }
      }]
  }
  };

  const graphData = {
    labels: labels,
    datasets: [
      {
        label: "data show",
        data: data,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#27AE60",
        tension: 0.4,
        pointRadius: data.length ==1 ? 4 :  0,
        hoverPointRadius: 0,
      },
    ],
  };

  return (
    <>
      <div style={{ minWidth: "100%" }}>
        <Line data={graphData} options={options} />
      </div>
    </>
  );
};

export default LineChart;
