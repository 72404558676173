import {useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SearchClose from "../../assets/img/close-icon.png";
import { capitalizeFirstLetter } from "../../util/util";

interface IProps {
  checkIndex: any;
  selectedUser: any;
  selectedUserName: string;
  userData:any;
  userId:any;
}
const SideBar = ({ checkIndex, selectedUserName ,userData,userId}: IProps) => {
  const location = useLocation();
  const [searchUsers, setSearchUsers] = useState(userData);
  const [searchText, setSearchText] = useState("");
  

  const searchingUser = (words?: any) => {
    setSearchText(words);
    if (words.length > 0) {
      const users = userData.filter((item: any) => {
        const fullName = item.userDetail.firstName + item.userDetail.lastName
        return fullName.toLowerCase().includes(words.toLowerCase());
      });
      setSearchUsers(users);
    } else {
      setSearchUsers(userData);
    }
  };
  useEffect(()=>{
    setSearchUsers(userData)
  },[userId])
  return (
    <>
      <div className="sidenav-menu adminDestop">
        <div className="menu-side">
          <ul className="nav-list">
            <li className="item">
              <div className="search-bar1 HideSearch">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  onChange={() => console.log()}
                />
                <img
                  className="searchCloseIcon"
                  src={SearchClose}
                  alt=""
                  onClick={() => {
                    searchingUser("");
                  }}
                />
              </div>
            </li>
            <li className="item">
              <a
                className="nav-link dropdown collapsed clientsHeading"
                data-bs-toggle="collapse"
                data-bs-target="#collapseUtilities"
                aria-expanded="false"
                aria-controls="collapseUtilities"
              >
                Clients
              </a>
              <div
                className="collapse"
                id="collapseUtilities"
                data-bs-parent="#accordionSidenav"
              >
                <div className="search-bar1 ClientSearch">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e: any) => searchingUser(e.target.value)}
                  />
                  <img
                    className="searchCloseIcon"
                    src={SearchClose}
                    onClick={() => {
                      searchingUser("");
                    }}
                    alt=""
                  />
                </div>
                <nav className="sidenav-menu-nested nav">
                  {
                   searchUsers ? searchUsers.map((item:any,index:any)=>{
                      return (
                        <div
                          className={
                            userId==item.id
                              ? "user-name active"
                              : "user-name "
                          }
                          key={index}
                          onClick={() =>checkIndex(item.id) }
                        >
                          <span >{`${capitalizeFirstLetter(item.userDetail.firstName)}  ${capitalizeFirstLetter(item.userDetail.lastName)} `}</span>
                        </div>
                      );
                    }) : <></>
                  }
      
                </nav>
              </div>
            </li>
            <li className="item darkDashboard">
              <a className="nav-link">{selectedUserName}</a>
              <ul className="sub-list">
                <li>
                  <Link
                    to="/dashboard"
                    className={
                      location.pathname.includes("dashboard") ? "active" : ""
                    }
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/kpi"
                    className={
                      location.pathname.includes("kpi") ? "active" : ""
                    }
                  >
                    KPI
                  </Link>
                </li>
                <li>
                  <Link
                    to="/checkup-result"
                    className={
                      location.pathname.includes("checkup-result")
                        ? "active"
                        : ""
                    }
                  >
                    Blood Results
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default SideBar;
